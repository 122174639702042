(function($) {

  var methods = {
    init: function(options) {

      // options
      var defaults = {
        debug: false     // whether to log debug messages
      }
      var $this = $(this);
      var opts = $.extend(defaults, options, $this.data());

      // store data
      $this.data('scrollShow',opts);

      if (opts.scrollshow) {
        var element = $(opts.scrollshow);
        $(window).on('scroll', function() { methods['doScroll'].call($this, element) } ).trigger('scroll');
      }

      return this;

    },

    doScroll: function(element) {
      var $this = this;
      var offset = element.offset();
      if ( $(window).scrollTop() > offset.top && $this.hasClass('d-none') )
        $this.removeClass('d-none');
      else if ( $(window).scrollTop() <= offset.top && !$this.hasClass('d-none') )
        $this.addClass('d-none');
    },

  }

  $.fn.scrollShow = function(method) {

    if ( methods[method] ) {
      return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ) );
    } else if ( typeof method === 'object' || ! method ) {
      return methods.init.apply( this, arguments );
    } else {
      $.error( 'Method ' +  method + ' does not exist on jQuery.scrollShow' );
    }

  }

})(jQuery);
