(function($) {

  var methods = {
    init: function(options) {

      if (options && options.debug) {
        console.group('[checkboxControls->init]');
        console.log(this);
      }

      return this.each( function() {

        // options
        var defaults = {
          debug: false     // whether to log debug messages
        }
        var $this = $(this);
        var opts = $.extend({}, defaults, options, $this.data());

        // store data
        $this.data('checkboxControls',opts);

        $this.find('.fa-check').click( function(e) { methods['selectAll'].call($this); } ).css('cursor','pointer');
        $this.find('.fa-ban').click( function(e) { methods['selectNone'].call($this); } ).css('cursor','pointer');
        $this.find('.fa-random').click( function(e) { methods['selectInverse'].call($this); } ).css('cursor','pointer');

      } );

      if (options && options.debug)
        console.groupEnd();

    },

    selectAll: function() {
      var $this = this;
      var opts = $this.data('checkboxControls');

      if (opts.debug)
        console.group('[checkboxControls->selectAll]');

      var parent = $this.parents('tbody');
      if (!parent.length)
        parent = $this.parents('table');
      if (!parent.length)
        parent = $($this.parents('div')[0]);

      parent.find('input[type=checkbox]').prop('checked','checked');

      if (opts.debug)
        console.groupEnd();
    },

    selectNone: function() {
      var $this = this;
      var opts = $this.data('checkboxControls');

      if (opts.debug)
        console.group('[checkboxControls->selectNone]');

      var parent = $this.parents('tbody');
      if (!parent.length)
        parent = $this.parents('table');
      if (!parent.length)
        parent = $($this.parents('div')[0]);

      parent.find('input[type=checkbox]').prop('checked',false);

      if (opts.debug)
        console.groupEnd();
    },

    selectInverse: function() {
      var $this = this;
      var opts = $this.data('checkboxControls');

      if (opts.debug)
        console.group('[checkboxControls->selectInverse]');

      var parent = $this.parents('tbody');
      if (!parent.length)
        parent = $this.parents('table');
      if (!parent.length)
        parent = $($this.parents('div')[0]);

      var checked = parent.find('input[type=checkbox]:checked');
      var unchecked = parent.find('input[type=checkbox]').not(':checked');

      checked.prop('checked',false);
      unchecked.prop('checked','checked');

      if (opts.debug)
        console.groupEnd();
    },

  }

  $.fn.checkboxControls = function(method) {

    if ( methods[method] ) {
      return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ) );
    } else if ( typeof method === 'object' || ! method ) {
      return methods.init.apply( this, arguments );
    } else {
      $.error( 'Method ' +  method + ' does not exist on jQuery.checkboxControls' );
    }

  }

})(jQuery);
