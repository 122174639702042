( function() {
  var $ = jQuery;

  $.notify = function(message, options) {
    var $alert, css, offsetAmount;
    options = $.extend({}, $.notify.default_options, options);

    $alert = $("<div>");
    $alert.attr("class", "notify alert");
    if (options.type) {
      $alert.addClass("alert-" + options.type);
    }

    if (options.allow_dismiss) {
      $alert.addClass("alert-dismissible");
      $alert.append("<button  class=\"close\" data-dismiss=\"alert\" type=\"button\"><span aria-hidden=\"true\">&#215;</span><span class=\"sr-only\">Close</span></button>");
    }
    $alert.append(message);

    if (options.top_offset) {
      options.offset = {
        from: "top",
        amount: options.top_offset
      };
    }
    offsetAmount = options.offset.amount;
    $(".notify").each(function() {
      return offsetAmount = Math.max(offsetAmount, parseInt($(this).css(options.offset.from)) + $(this).outerHeight() + options.stackup_spacing);
    });

    css = {
      "position": (options.element === "body" ? "fixed" : "relative"),
      'margin-top': 0,
      'margin-bottom': 0,
      "z-index": options['z-index'],
      "display": "none"
    };

    if (css.position == 'fixed') {
      css[options.offset.from] = offsetAmount + "px";
    } else {
      css['margin-' + options.offset.from] = options.offset.amount + 'px';
    }
    $alert.css(css);

    if (options.width !== "auto") {
      if (typeof options.width == 'number')
        $alert.css("width", parseInt(options.width) + "px");
      else
        $alert.css('width', options.width);
    }
    $(options.element).append($alert);

    switch (options.align) {
    case "center":
      $alert.css({
        "margin-left": 'auto',
        'margin-right': 'auto',
      });
      break;
    case "left":
      $alert.css("margin-left", "20px");
      break;
    default:
      $alert.css("margin-right", "20px");
    }

    $alert.fadeIn();
    if (options.delay > 0) {
      $alert.delay(options.delay).fadeOut(function() {
        return $(this).alert("close");
      });
    }

    return $alert;
  };

  $.notify.default_options = {
    element: "body",
    type: "info",
    offset: {
      from: "top",
      amount: 10
    },
    align: "right",
    width: 250,
    delay: 4000,
    allow_dismiss: true,
    stackup_spacing: 10,
    "z-index": "9999"
  };

}).call(this);
