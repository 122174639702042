(function($) {

  var methods = {
    init: function(options) {

      if (options && options.debug) {
        console.group('[activate->init]');
        console.log(this);
      }

      return this.each( function() {

        // options
        var defaults = {
          debug: false,     // whether to log debug messages
          field: 'active'
        }
        var $this = $(this);

        var opts = $.extend({}, defaults, options, $this.data());
//        console.log(opts);

        if (!opts.key) {
          if (opts.debug)
            console.log('A field name is required');
          return;
        }

        if (!opts.field) {
          if (opts.debug)
            console.log('A field name is required');
          return;
        }

        if (!opts.url) {
          if (opts.debug)
            console.log('A URL is required');
          return;
        }

        if (!opts[ opts.key ])
          opts[ opts.key ] = $this.parents('[' + opts.key + ']').attr(opts.key);

        if (!opts[ opts.key ]) {
          if (opts.debug)
            console.log($this, 'Unable to find the a value for ' + opts.key + '. Either specify it on the element with "data-' + opts.key + '" or in the attribute of one of the parent elements.');
          return;
        }

        // store data
        $this.data('activate',opts);

        $this.click( function(e) { e.preventDefault(); e.stopPropagation(); methods['_activate'].call($this); return false; } ).css('cursor','pointer');

      } );

      if (options && options.debug)
        console.groupEnd();

    },

    _activate: function() {

      var $this = this;
      var opts = $this.data('activate');

      if (opts.debug)
        console.group('[activate->_activate]');

      var data = {};
      data[ opts.key ] = opts[opts.key];
      data[ opts.field ] = $this.find('.fa-check').length ? 'f' : 't';

      var options = {
        url: opts.url,
        data: data,
        success: _update_active
      }
      var options = $.extend({}, ajax_options, options);
      if (opts.debug)
        console.log('options:',options);
      $.ajax(options);

      function _update_active(data) {
        if (opts.debug)
          console.log('ajax data:',data);

        if (data.error) {
          error_message(data.error);
          return;
        }

        $this.html( format_check_cancel(data[ opts.field ]) );
      };

      if (opts.debug)
        console.groupEnd();

    },

  }

  $.fn.activate = function(method) {

    if ( methods[method] ) {
      return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ) );
    } else if ( typeof method === 'object' || ! method ) {
      return methods.init.apply( this, arguments );
    } else {
      $.error( 'Method ' +  method + ' does not exist on jQuery.activate' );
    }

  }

})(jQuery);
