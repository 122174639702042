(function($) {

  var methods = {
    init: function(options) {

      // options
      var defaults = {
        debug: false     // whether to log debug messages
      }
      var $this = $(this);
      var opts = $.extend(defaults, options, $this.data());

      // store data
      $this.data('scrollToTop',opts);
      $this.click( function() {
        $([document.documentElement, document.body]).animate({
          scrollTop: $(opts.scrolltotop).offset().top
        }, 500);
      } );

      return this;

    },

  }

  $.fn.scrollToTop = function(method) {

    if ( methods[method] ) {
      return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ) );
    } else if ( typeof method === 'object' || ! method ) {
      return methods.init.apply( this, arguments );
    } else {
      $.error( 'Method ' +  method + ' does not exist on jQuery.scrollToTop' );
    }

  }

})(jQuery);
