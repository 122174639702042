(function($) {

  var current_page, next_page, previous_page; // form pages
  var left, opacity, scale; //fieldset properties which we will animate
  var animating; // flag to prevent quick multi-click glitches

  var methods = {
    init: function(options) {

      // options
      var defaults = {
        debug: false,     // whether to log debug messages
        validateOnNext: true,
        validateOnSave: true,
        scrollOnNext: true,
        //        confirm: false

        animate: 'bounce',

        validators: {
          'required': methods['checkRequired'],
          'one_of': methods['checkRequiredOneOf']
        },
      }
      var $this = $(this);

      var opts = $.extend({}, defaults, options, $this.data());

      if (opts.debug)
        console.group('[multipartForm]', opts);

      // store data
      $this.data('multipartForm',opts);

      // interactions
      $this.find('.next').off('click').click( function(e) { methods['nextPage'].call($this, this); } );
      $this.find('.previous').off('click').click( function(e) { methods['previousPage'].call($this, this); } );
      $this.find('.save').off('click').click( function(e) { return methods['save'].call($this, this); } );

      if (opts.debug)
        console.groupEnd();

    },

    nextPage: function(button) {

      var $this = this;
      var opts = $this.data('multipartForm');

      if (opts.debug)
        console.group('[multipartForm->nextPage]');

      if (animating) {
        if (opts.debug) {
          console.log('animating', opts.animate);
          console.groupEnd();
        }
        return false;
      }

      current_page = $(button).parent();
      next_page = $(button).parent().next();

      if (opts.validateOnNext) {
        if (!methods['validate'].call($this, current_page)) {
          console.log('Validation failed.');
          console.groupEnd();
          return false;
        }
      }

      //      save_application(); --> move to onChange

      $this.trigger('change', { button: button, current_page: current_page });
      $this.trigger('next', { button: button, current_page: current_page, next_page: next_page });

      animating = true;

      // scroll to top
      $([document.documentElement, document.body]).animate({
        scrollTop: $this.offset().top
      }, 500);

      // activate next step on progressbar using the index of next_page
      $('.progress-tracker li').eq($('div.form-page').index(next_page)).addClass('active');

      //show the next page
      next_page.show();

      //hide the current fieldset with style
      if (opts.animate == 'bounce') {
        current_page.animate({opacity: 0}, {
          step: function(now, mx) {
            //as the opacity of current_page reduces to 0 - stored in "now"

            //1. scale current_page down to 80%
            scale = 1 - (1 - now) * 0.2;

            //2. bring next_page from the right(50%)
            left = (now * 50) + '%';

            //3. increase opacity of next_page to 1 as it moves in
            opacity = 1 - now;
            current_page.css({
              'transform': 'scale(' + scale + ')',
              'position': 'absolute'
            });
            next_page.css({'left': left, 'opacity': opacity});
          },
          duration: 800,
          complete: function() {
            current_page.hide();
            if (opts.debug)
              console.log('bounce animation complete');
            animating = false;
          },

          //this comes from the custom easin gplugin
          easing: 'easeInOutBack'
        });
      } else if (opts.animate == 'slide') {
        current_page.animate({opacity: 0}, {
          step: function(now, mx) {

          },
          duration: 600,
          complete: function() {
            current_page.hide();
            if (opts.debug)
              console.log('slide animation complete');
            animating = false;
          }
        });
      } else {
        current_page.animate({opacity: 0}, {
          step: function(now, mx) {

          },
          duration: 600,
          complete: function() {
            current_page.hide();
            if (opts.debug)
              console.log('default animation complete');
            animating = false;
          }
        });
      }

      if (opts.debug) {
        console.groupEnd();
      }

    },

    previousPage: function(button) {

      var $this = this;
      var opts = $this.data('multipartForm');

      if (opts.debug)
        console.group('[multipartForm->previousPage]');

      if (animating)
        return false;
      animating = true;

      // scroll to top
      $([document.documentElement, document.body]).animate({
        scrollTop: $this.offset().top
      }, 500);

      current_page = $(button).parent();
      previous_page = $(button).parent().prev();

      $this.trigger('change', { button: button, current_page: current_page });
      $this.trigger('previous', { button: button, current_page: current_page, previous_page: previous_page });

      //de-activate current step on progressbar
      $('.progress-tracker li').eq($('div.form-page').index(current_page)).removeClass('active');

      //show the previous fieldset
      previous_page.show();

      //hide the current fieldset with style
      current_page.animate({opacity: 0}, {
        step: function(now, mx) {
          //as the opacity of current_page reduces to 0 - stored in "now"

          //1. scale previous_page from 80% to 100%
          scale = 0.8 + (1 - now) * 0.2;

          //2. take current_page to the right(50%) - from 0%
          left = ((1-now) * 50)+'%';

          //3. increase opacity of previous_page to 1 as it moves in
          opacity = 1 - now;
          current_page.css({'left': left});
          previous_page.css({'transform': 'scale(' + scale + ')', 'opacity': opacity});
        },
        duration: 800,
        complete: function(){
          current_page.hide();
          previous_page.css('position','');
          animating = false;
        },

        //this comes from the custom easing plugin
        easing: 'easeInOutBack'
      });

      if (opts.debug)
        console.groupEnd();

    },

    save: function(button) {

      var $this = this;
      var opts = $this.data('multipartForm');

      if (opts.debug)
        console.group('[multipartForm->save]');

      if (opts.validateOnSave) {
        if (!methods['validate'].call($this)) {
          console.log('Validation failed.');
          return false;
        }
      }

      if (opts.debug)
        console.log($this, button);
      $this.trigger('save');

      if (opts.debug)
        console.groupEnd();

      return true;

    },


    /***** VALIDATION *****/

    addValidator: function(validator) {
      var $this = this;
      var opts = $this.data('multipartForm');
      $.extend(opts.validators, validator);
    },

    validate: function() {
      var $this = this;
      var opts = $this.data('multipartForm');

      if (opts.debug)
        console.log('[validate]', opts.validators);

      var parent = $this;
      if (arguments.length > 0)
        parent = arguments[0];

      // clear errors
      parent.find('input,textarea,select').removeClass('is-invalid');
      parent.find('label').removeClass('text-danger');

      var error = false;
      var errors = {};
      for (var type in opts.validators) {
        var method = opts.validators[type];
        var entities = parent.find('input[validate*="' + type + '"],textarea[validate*="' + type + '"],select[validate*="' + type + '"]');

        if (entities.length) {

          var entities_data = {};

          entities.each( function() {

            var input = $(this);
            var input_error = method.call($this, input, entities, entities_data);

            var field = input.attr('name');
            var id = input.attr('id');
            var label = input.siblings('label[for="' + id + '"]');

            var validator = input.attr('validate');
            var group = '';
            if (validator.search(type + '\\[') !== -1) {
              var start = validator.search(type + '\\[') + type.length + 1;
              var end = validator.search('\\]', start);
              group = validator.substring(start, end );
            }

            if ( input_error ) {
              error = true;

              if (!errors[type])
                errors[type] = [];

              if (group !== '' && !errors[type][group])
                errors[type][group] = [];


              var error_data = {};

              input.addClass('is-invalid');
              label.addClass('text-danger');

              if (label && label.text())
                error_data.label = label.text();
              else if ( input.is(':checkbox') && input.attr('label') )
                error_data.label = input.attr('label');

              error_data.type = type;
              error_data.field = field;
              error_data.group = group;

              if (group !== '')
                errors[type][group].push(error_data);
              else
                errors[type].push(error_data);
            }

          } );
        }
      }

      if (error) {
        //console.log('errors:', errors);
        $this.trigger('validationError', errors);
        return false;
      }

      return true;

    },

    checkRequired: function(input) {

      var field = input.attr('name');
      var value = input.val();
      if (input.is(':file')) {
        input = input.siblings('[type=hidden][name=' + field + ']');
        value = input.val();
      }

      if ( input.is(':checkbox') && !input.is(':checked') )
        return true;

      if (value == '')
        return true;

      return false;

    },

    checkRequiredOneOf: function(input, entities, entities_data) {

      var error = true;

      var field = input.attr('name');
      var val = input.attr('validate');

      if (val.indexOf('[') !== false)
        field = val.substring( val.indexOf('[') + 1, val.indexOf(']') );

      if (typeof entities_data[field] !== 'undefined')
        return entities_data[field];

      entities = entities.filter('[validate*="[' + field + ']"]');

      entities.each( function() {
        var input = $(this);
        if ( !input.is(':checkbox') && input.val() !== '' || input.is(':checkbox') && input.is(':checked') )
          error = false;
      } );

      entities_data[field] = error;
      return error;

    },

  }

  $.fn.multipartForm = function(method) {

    if ( methods[method] ) {
      return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ) );
    } else if ( typeof method === 'object' || ! method ) {
      return methods.init.apply( this, arguments );
    } else {
      $.error( 'Method ' +  method + ' does not exist on jQuery.multipartForm' );
    }

  }

})(jQuery);
