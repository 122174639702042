(function($) {

  var methods = {
    init: function(options) {

      if (options && options.debug)
        console.group('[deleteRow->init]');

      return this.each( function() {

        // options
        var defaults = {
          debug: false,     // whether to log debug messages
        }
        var $this = $(this);

        var opts = $.extend({}, defaults, options, $this.data());
//        console.log(opts);

        if (!opts.field) {
          if (opts.debug)
            console.log('A field name is required');
          return;
        }

        if (!opts.url) {
          if (opts.debug)
            console.log('A URL is required');
          return;
        }

        if (!opts[ opts.field ])
          opts[ opts.field ] = $this.parents('[' + opts.field + ']').attr(opts.field);

        if (!opts[ opts.field ]) {
          if (opts.debug)
            console.log($this, 'Unable to find the a value for ' + opts.field + '. Either specify it on the element with "data-' + opts.field + '" or in the attribute of one of the parent elements.');
          return;
        }

        // store data
        $this.data('deleteRow',opts);

        $this.click( function(e) { e.preventDefault(); e.stopPropagation(); methods['_deleteRow'].call($this); return false; } );

      } );

      if (options && options.debug)
        console.groupEnd();

    },

    _deleteRow: function() {

      var $this = this;
      var opts = $this.data('deleteRow');

      if (opts.debug)
        console.group('[deleteRow->_deleteRow]');

      if ( !confirm('Are you sure you want to delete this row?  This cannot be undone.') )
        return;

      var data = {};
      data[ opts.field ] = opts[opts.field];

      var options = {
        url: opts.url,
        data: data,
        success: _delete_row
      }
      var options = $.extend({}, ajax_options, options);
      if (opts.debug)
        console.log('options:',options);
      $.ajax(options);

      function _delete_row(data) {
        if (opts.debug)
          console.log('ajax data:',data);

        if (data.error) {
          error_message(data.error);
          return;
        }

        var table = $this.parents('table.sortable');
        $this.parents('tr').fadeOut('normal', function() { $(this).remove(); table.trigger('applyWidgets'); } );

      };

      if (opts.debug)
        console.groupEnd();

    },

  }

  $.fn.deleteRow = function(method) {

    if ( methods[method] ) {
      return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ) );
    } else if ( typeof method === 'object' || ! method ) {
      return methods.init.apply( this, arguments );
    } else {
      $.error( 'Method ' +  method + ' does not exist on jQuery.deleteRow' );
    }

  }

})(jQuery);
