(function($) {

  var methods = {
    init: function(options) {

      return this.each( function() {

        // options
        var defaults = {
          debug: false,     // whether to log debug messages
          target: false,
          url: false
        }
        var $this = $(this);
        var opts = $.extend(defaults, options, $this.data());

        // error checking
        if (!opts.target) {
          error_message('[jQuery.markdown] A target is required');
          return false;
        }

        opts.target = $(opts.target);

        // store data
        $this.data('markdown',opts);

        $this.height( $(window).height() - 20 );
        opts.target.height( $(window).height() - 20 );

        $this.keyup( function() { methods['parse'].call($this) } ).keyup();

        return this;

      } );

    },

    parse: function(element) {
      var $this = this;
      var opts = $this.data('markdown');

      var options = {
        url: opts.url,
        dataType: 'html',
        data: {
          input: $this.val()
        },
        success: _update_target,
        type: 'post',
        error: log_errors
      }
      $.ajax(options);

      function _update_target(data) {
        opts.target.html(data);
        if (opts.source)
          opts.source.html(nl2br(escapeHtml(data)));
      }
    },

  }

  $.fn.markdown = function(method) {

    if ( methods[method] ) {
      return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ) );
    } else if ( typeof method === 'object' || ! method ) {
      return methods.init.apply( this, arguments );
    } else {
      $.error( 'Method ' +  method + ' does not exist on jQuery.markdown' );
    }

  }

})(jQuery);
